//-----------------------------------------------------------// In-built Imports // ------------------------------

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMessageList } from '../../actions';

//-----------------------------------------------------------// External Imports // ------------------------------
const replacePlaceholders = (str, values) => {
  return str
    .replace(/%s/g, () => (values.length > 0 ? values.shift() : ''))
    .replace('  ', ' ')
    .trim();
};
export const useCustomMessageHook = () => {
  const translationsList = useSelector(state => state.commonReducer.translationsList);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!translationsList) {
      dispatch(fetchMessageList('sync', 'translationsList'));
    }
  }, [translationsList]);

  const translateMessage = (messageName, isLongDesc = false, ...args) => {
    //  check database filed to be used to show massage translate value
    const messageFiled = isLongDesc ? 'long_desc' : 'short_desc';
    const msg = translationsList.find(ele => ele.message_name === messageName);
    if (msg) {
      return replacePlaceholders(msg[messageFiled], [...args]);
    }
    return '';
  };

  return { translateMessage };
};
